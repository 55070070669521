

import { Activity, ActualActivity, Phase, Program } from "@/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";


@Component({
    components: {
        AddActualActivityDialog: () => import('@/components/dialogs/AddActualActivity.vue'),
        EditActualActivity: () => import('@/components/dialogs/EditActualActivity.vue'),
    }
})
export default class ProgramPhaseControls extends Vue {
    @Prop({ required: true }) program!: Program;
    @Prop({ required: true }) phases!: Phase[];
    @Prop({ required: true }) activities!: Activity[];
    @Prop({ required: true }) actualActivities!: ActualActivity[];

    open = false;
    tab = 'total';

    valueToPercentage(value: number, total: number) {
        return Math.round((value / total) * 100);
    }

    get totalStats() {
        const plannedHours = this.activities.map(e => e.plannedHours).reduce((a, b) => a! + b!, 0);

        const actualHours = this.actualActivities.map(e => e.actualHours).reduce((a, b) => a! + b!, 0);
        const confirmedHours = this.actualActivities.filter(e => e.participantConfirmed).map(e => e.actualHours).reduce((a, b) => a! + b!, 0);

        return {
            plannedHours,
            actualHours,
            confirmedHours,
        }
    }

    statsForPhase(phase: number) {
        // phase is the index of the phase
        const plannedHours = this.activities.filter(e => e.phaseId === this.phases[phase].id).map(e => e.plannedHours).reduce((a, b) => a! + b!, 0);

        const activitiesForPhase = this.activities.filter(e => e.phaseId === this.phases[phase].id);
        const actualHours = this.actualActivities.filter(e => activitiesForPhase.map(e => e.id).includes(e.activityId)).map(e => e.actualHours).reduce((a, b) => a! + b!, 0);

        const actualActivitiesForPhase = this.actualActivities.filter(e => activitiesForPhase.map(e => e.id).includes(e.activityId));
        return {
            plannedHours,
            actualHours: actualHours,
            confirmedHours: actualActivitiesForPhase.filter(e => e.participantConfirmed).map(e => e.actualHours).reduce((a, b) => a! + b!, 0),
        }
    }


    getSeries(phase?: number) {
        if (phase === undefined) return [this.totalStats.plannedHours, this.totalStats.actualHours, this.totalStats.confirmedHours].map(e => this.valueToPercentage(e, this.totalStats.plannedHours));
        return [this.statsForPhase(phase).plannedHours, this.statsForPhase(phase).actualHours, this.statsForPhase(phase).confirmedHours].map(e => this.valueToPercentage(e, this.statsForPhase(phase).plannedHours));
    }

    get series() {
        return this.tab === 'total' ? this.getSeries() : this.getSeries(parseInt(this.tab) - 1);
    }

    get chartOptions() {
        return {
            chart: {
                height: 390,
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    offsetY: 0,
                    startAngle: 0,
                    endAngle: 270,
                    hollow: {
                        margin: 5,
                        size: '30%',
                        background: 'transparent',
                        image: undefined,
                    },
                    dataLabels: {
                        name: {
                            show: false,
                        },
                        value: {
                            show: false,
                        }
                    },
                    barLabels: {
                        enabled: true,
                        useSeriesColors: true,
                        offsetX: -8,
                        fontSize: '16px',
                        formatter: (seriesName: any, opts: any) => {
                            const stats = this.tab === 'total' ? this.totalStats : this.statsForPhase(parseInt(this.tab)- 1);
                            let addon = '';
                            if (opts.seriesIndex === 0) addon = stats.plannedHours + 'h';
                            if (opts.seriesIndex === 1) addon = stats.actualHours + 'h';
                            if (opts.seriesIndex === 2) addon = stats.confirmedHours + 'h';
                            return `${seriesName}: ${addon}`
                            // return seriesName + addon + ":  " + opts.w.globals.series[opts.seriesIndex] + "%"
                        },
                    },
                }
            },
            colors: ['#808080', '#1ab7ea', '#4BB543'],
            labels: [`Gepland`, 'Voltooid', 'Bevestigd'],
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        show: false
                    }
                }
            }]
        }
    }
}
